import React, { Component } from 'react';
import { setSession } from '../actions';
import { connect } from 'react-redux';
import { Spinner } from '../utils';
import WebsocketInstance from '../utils/websocket';
import { getCookie } from '../utils/cookieParser';

class LandingPage extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    if (window.location.pathname.search('/checkout') < 0) {
      const session = getCookie('swsSession');
      console.log('session: ', session);
      console.log('document.cookie: ', document.cookie);
      this.props.setSession(session);
      let interval = setInterval(() => {
        if (WebsocketInstance.isWebsocketConnected()) {
          clearInterval(interval);
          this.setState({
            loading: false,
          });
          this.props.history.push('/plans');
        }
      }, 200);
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    return <div>{this.state.loading ? <Spinner title="Loading..." /> : null}</div>;
  }
}

export default connect(null, { setSession })(LandingPage);
