import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import * as appConstants from '../utils/appConstants';
import CardPayment from '../components/cardPayment';
import WalletPayment from '../components/walletPayment';
import OptionsTab from '../utils/optionsTab';
import { checkSession, getConfig } from '../utils';

class StripeMain extends Component {
  state = {
    activeTab: appConstants.tabs.card,
  };

  componentDidMount() {
    console.log('stripemain before checkSession');
    checkSession();
    console.log('stripemain after checkSession');
  }

  handleTabs = (tab) => {
    this.setState({ activeTab: tab });
  };

  renderPaymentMethod = (activeTab) => {
    if (activeTab === appConstants.tabs.card) {
      return <CardPayment {...this.props} planDetails={this.props.history.location.state.planDetails} payType="card" />;
    } else {
      return (
        <WalletPayment {...this.props} planDetails={this.props.history.location.state.planDetails} payType="wallet" />
      );
    }
  };

  render() {
    return (
      <div>
        <OptionsTab
          handleTabs={this.handleTabs}
          activeTab={this.state.activeTab}
          option1={appConstants.tabs.card}
          option2={appConstants.tabs.wallet}
          shouldNoyDisplayOption2={true}
        />
        <StripeProvider apiKey={getConfig().STRIPE_KEY}>
          <div className="elementsContainer">
            <Elements>{this.renderPaymentMethod(this.state.activeTab)}</Elements>
          </div>
        </StripeProvider>
      </div>
    );
  }
}

export default StripeMain;
