import { getConfig } from '../utils';
import { getCookie } from './cookieParser';

export function handlePrimusResponse(action, array) {
  if (array.length) {
    let isDataPresent = false;
    array.map((item) => {
      if (item._handler === action.payload._handler) {
        item.data = action.payload.data;
        isDataPresent = true;
      }
      return array;
    });
    if (!isDataPresent && action.payload._handler) {
      array.push(action.payload);
    }
    return array;
  } else {
    if (action.payload._handler) {
      array.push(action.payload);
      return array;
    }
  }
}

export function checkSession() {
  if (!getCookie('swsSession')) {
    window.location.href = getConfig().REDIRECTION_URL;
  }
  return null;
}
