import React, { Component } from 'react';
import * as appConstants from '../utils/appConstants';

export default class OptionsTab extends Component {
  changeTabs = (tab) => {
    this.props.handleTabs(tab);
  };
  render() {
    if (this.props.shouldNoyDisplayOption2) {
      return null;
    } else {
      return (
        <div className="tabs">
          <div className="nav nav-pills">
            <span className="nav-item">
              <span
                className={this.props.activeTab === this.props.option1 ? 'nav-link open' : 'nav-link'}
                onClick={() => {
                  this.changeTabs(this.props.option1);
                }}
              >
                {this.props.option1.charAt(0).toUpperCase() + this.props.option1.slice(1)}
              </span>
            </span>
            <span className="nav-item">
              <span
                className={this.props.activeTab === this.props.option2 ? 'nav-link open' : 'nav-link'}
                onClick={() => {
                  this.changeTabs(this.props.option2);
                }}
              >
                {this.props.option2 === appConstants.tabs.purchase
                  ? appConstants.tabs.purchaseATest
                  : this.props.option2.charAt(0).toUpperCase() + this.props.option2.slice(1)}
              </span>
            </span>
          </div>
        </div>
      );
    }
  }
}
