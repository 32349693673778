import React from 'react';
import * as appConstants from '../utils/appConstants';
import is from 'is_js';
import { PaymentRequestButtonElement, injectStripe } from 'react-stripe-elements';
import { SimpleSpinner, convertToSubUnit } from '../utils';

class WalletPayment extends React.Component {
  state = {
    canMakePayment: false,
    paymentRequest: '',
    paymentRequestButtonReady: false,
    loading: true,
  };

  componentDidMount() {
    let paymentRequest = this.props.stripe.paymentRequest({
      country: appConstants.countryCode.sweden,
      currency: appConstants.currency.sek,
      total: {
        label: 'Total amount',
        amount: convertToSubUnit(this.props.planDetails.price),
      },
    });
    paymentRequest.on('token', ({ complete, token, ...data }) => {
      console.log('Received Stripe token: ', token);
      console.log('Received customer information: ', data);
      complete('success');
      //TODO: send tokens to back-end for payment process
    });
    paymentRequest.canMakePayment().then((result) => {
      this.setState({ canMakePayment: result, paymentRequest: paymentRequest, loading: false });
    });
  }

  showIncompatibilityMessage = () => {
    if (is.chrome() || navigator.userAgent.match('CriOS')) {
      return (
        <div className="incompatibility">
          <h5>You are watching this message because you have not saved any card details in chrome</h5>
          <br />
          <ol>
            <h6>If you are on Computer:</h6>
            <li>
              At the top right, click Profile&nbsp; > <strong>Payment methods</strong> or <strong>Addresses</strong>.
            </li>
            <li>
              <strong>Add</strong>: Next to "Payment methods" or "Addresses," click <strong>Add</strong>.
            </li>
            <li>
              <strong>Edit</strong>: To the right of the card or address, click More > <strong>Edit</strong>.
            </li>
            <li>
              <strong>Delete</strong>: To the right of the card or address, <br />
              click More > <strong>Remove</strong>. You might need to wait a few minutes for your card to be removed.
            </li>
          </ol>
          <br />
          <ol>
            <h6>If you are on Android Mobile device:</h6>
            <li>
              At the top right, tap on <b>&#8942;</b>&nbsp; > <strong>Payment methods</strong>.
            </li>
            <li>
              <strong>Add</strong>: In "Payment methods" tap <strong>Add</strong>.
            </li>
            <li>
              <strong>Edit</strong>: Click on the saved card to Edit.
            </li>
            <li>
              <strong>Delete</strong>: Click on the saved card and tap on trash icon
            </li>
          </ol>
        </div>
      );
    } else if (is.safari()) {
      return (
        <div className="incompatibility">
          <h5>You are watching this message because you have not saved any card details in Safari</h5>
          <br />
          <ol>
            <h6>To add the card details:</h6>
            <li>Go to settings</li>
            <li>Select "Safari" from settings Menu</li>
            <li>
              <strong>Add</strong>: Select "AutoFill", click <strong>Saved Credit Cards</strong> >{' '}
              <strong>Add Credit Cards</strong>.
            </li>
            <li>
              <strong>Edit</strong>: Select Saved Credit Card and click <strong>Edit</strong> on top right corner.
            </li>
            <li>
              <strong>Delete</strong>: Select Saved Credit Card and click <strong>Edit</strong> on top right corner,
              select <strong>Delete</strong> at bottom of the card details.
            </li>
          </ol>
        </div>
      );
    } else {
      return <div>You are using an unsupported browser, Please use Google Chrome or Safari for complete support</div>;
    }
  };

  handleReady = () => {
    this.setState({ paymentRequestButtonReady: true });
  };

  renderPaymentRequestButton = () => {
    return (
      <div className="orderInfo">
        {this.state.paymentRequestButtonReady ? null : <SimpleSpinner />}
        <center>
          <div className="row data">
            <div className="col-sm-6">
              <h6>Plan Type:</h6>
            </div>
            <div className="col-sm-6">
              <strong>Recurring</strong> {/* TODO: Take dynamic values */}
            </div>
          </div>
          <br />
          <div className="row data">
            <div className="col-sm-6">
              <h6>Plan Name:</h6>
            </div>
            <div className="col-sm-6">
              <strong>Standard</strong> {/* TODO: Take dynamic values */}
            </div>
          </div>
          <br />
          <div className="row data">
            <div className="col-sm-6">
              <h6>Total Amount:</h6>
            </div>
            <div className="col-sm-6">
              <strong>{`SEK ${this.props.planDetails.planPrice}`}</strong>
            </div>
          </div>
          <br />
          <div className="row data">
            <div className="col-sm-12">
              <PaymentRequestButtonElement
                onReady={this.handleReady}
                className="PaymentRequestButton"
                paymentRequest={this.state.paymentRequest}
                style={{
                  paymentRequestButton: {
                    theme: 'light-outline',
                    height: '54px',
                    type: 'buy',
                  },
                }}
              />
            </div>
          </div>
        </center>
      </div>
    );
  };

  render() {
    return this.state.loading ? (
      <SimpleSpinner />
    ) : this.state.canMakePayment ? (
      this.renderPaymentRequestButton()
    ) : (
      this.showIncompatibilityMessage()
    );
  }
}

export default injectStripe(WalletPayment);
