import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as appConstants from '../utils/appConstants';
import Plan from '../components/plan';
import OptionsTab from '../utils/optionsTab';
import { setPlanDetails, setSession } from '../actions';
import WebsocketInstance from '../utils/websocket';
import { checkSession, convertToSubUnit, Spinner, SimpleSpinner } from '../utils';
import { Toast } from '../utils';
import { redirectToCheckout } from '../actions/stripeHandler';
class SubscriptionPlans extends Component {
  state = {
    plans: [],
    defaultPlanKey: null,
    activeTabName: appConstants.tabs.purchase,
    selectedPlanDetails: null,
    planDetails: [],
    toggle: false,
    isInitialPageLoad: false,
    purchasePlan: {},
    encriptedSession: null,
    loading: false,
  };

  componentDidMount() {
    console.log('subscriptionplans before checkSession');
    checkSession();
    console.log('subscriptionplans after checkSession');
    let interval = setInterval(() => {
      if (WebsocketInstance.isWebsocketConnected()) {
        clearInterval(interval);
        WebsocketInstance.sendMessage({ _handler: 'load-plans', isReact: true });
      }
    }, 200);
  }

  componentWillReceiveProps = (props) => {
    if (props.create_payment && props.create_payment.data) {
      redirectToCheckout(props.create_payment.data);
    }
    if (props.load_plans && props.load_plans.success && props.load_plans.data) {
      props.load_plans.data.map((plan, index) => {
        if (plan.isRecommended && !this.state.isInitialPageLoad && plan.recurring) {
          this.setState({
            defaultPlanKey: plan._id,
            selectedPlanDetails: plan,
            isInitialPageLoad: true,
          });
          this.props.setPlanDetails(plan);
        }
        return plan;
      });

      let recurringPlan = props.load_plans.data.filter((plan, index) => {
        if (plan.recurring) {
          return plan;
        } else return null;
      });
      let purchasePlan = props.load_plans.data.filter((plan, index) => {
        if (!plan.recurring) {
          return plan;
        } else return null;
      });
      this.setState({
        planDetails: recurringPlan,
        purchasePlan,
      });
    } else if (props.load_plans && !props.load_plans.success) {
      Toast.successToast(props.load_plans.error);
    }
  };

  purchase = (planDetails) => {
    if (this.state.purchasePlan[0] && this.state.purchasePlan[0].price) {
      this.setState({
        loading: true,
      });
      let price = convertToSubUnit(planDetails.price);
      planDetails.pricePerTest = this.state.purchasePlan[0].price;
      let paymentDetails = {
        _handler: 'create-payment',
        credits: planDetails.credits ? planDetails.credits : 'Unlimited',
        planID: planDetails._id,
        payType: this.props.payType,
        planType: planDetails.name,
        selectedMonth: planDetails.selectedMonth,
        payMethod: 'card',
        totalDiscount: planDetails.totalDiscount,
        recurring: planDetails.recurring,
        isReact: true,
      };
      WebsocketInstance.sendMessage(paymentDetails);
    }
  };

  handleTabs = (tab) => {
    this.setState({ activeTabName: tab });
  };

  selectedPlan = (planDetails) => {
    this.setState({ defaultPlanKey: planDetails._id, selectedPlanDetails: planDetails });
  };

  renderPlans = () => {
    return this.state.planDetails.map((plan, index) => {
      return (
        <Plan
          selectedPlan={this.selectedPlan}
          wholeClass={plan._id === this.state.defaultPlanKey ? 'wholeDefault' : 'whole'}
          typeClass={plan._id === this.state.defaultPlanKey ? 'typeDefaultRecuring' : 'typeRecuring'}
          history={this.props.history}
          planDetails={plan}
          key={index}
          activeTabName={this.state.activeTabName}
          purchaseData={this.state.purchasePlan}
          activePlan={this.state.selectedPlanDetails}
          numberOfMonth={plan.discount}
          initialVariable={plan.name}
        />
      );
    });
  };

  recurringPlans = () => {
    // TODO: the commented code below will be uncommented when we want plan to be displayed to live users.
    return (
      <div>
        {/* {this.renderPlans()} */}
        {/* <div className="submit mt-5">
                    {this.state.selectedPlanDetails ? <span className="planSummary">Amount payable: <strong>{this.props.planDetails ? this.props.planDetails.price : null} SEK</strong>, No. of tests: {!this.state.selectedPlanDetails.credits ? <strong>&#8734;</strong> : <strong>{this.state.selectedPlanDetails.credits}</strong>}</span> : null}
                </div> */}
        <h4>To purchase a plan, please contact at</h4>
        <a style={{ color: '#295c88', fontWeight: 'bold' }} href="mailto:sales@futureskill.com">
          sales@futureskill.com
        </a>
      </div>
    );
  };

  purchaseTestPlans = () => {
    if (this.state.purchasePlan[0]) {
      return (
        <Plan
          selectedPlan={this.selectedPlan}
          history={this.props.history}
          activeTabName={this.state.activeTabName}
          purchaseData={this.state.purchasePlan[0]}
        />
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div>
            <SimpleSpinner />
            <Spinner title="Please wait while your payment is processing" />
          </div>
        ) : null}
        <OptionsTab
          handleTabs={this.handleTabs}
          activeTab={this.state.activeTabName}
          option1={appConstants.tabs.recurring}
          option2={appConstants.tabs.purchase}
        />
        <div className="subscriptionPlansTitle">
          {/* {this.state.activeTabName === appConstants.tabs.recurring ? <span>Select Plan to Purchase</span> : null} */}
        </div>
        <div id="container">
          {this.state.activeTabName === appConstants.tabs.recurring ? this.recurringPlans() : this.purchaseTestPlans()}
        </div>
        <div className="submit">
          {this.state.activeTabName === appConstants.tabs.recurring ? null : (
            <button className="buyButton" onClick={() => this.purchase(this.props.planDetails)}>
              Purchase
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    planDetails: state.data.planDetails,
    defaultPlanKey: state.data.defaultPlanKey,
    primus_response: state.data.primus_response,
    load_plans: state.data['load-plans'],
    create_payment: state.data['create-payment'],
  };
};

export default connect(mapStateToProps, { setPlanDetails, setSession })(SubscriptionPlans);
