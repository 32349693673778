import React, { Component } from 'react';
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements';
import { imageConstants, Spinner, SimpleSpinner } from '../utils';
import { connect } from 'react-redux';
import { redirectToCheckout } from '../actions/stripeHandler';

let cardReady = false;
let expiryReady = false;
let cvvReady = false;

class CardPayment extends Component {
  state = {
    firstName: '',
    lastName: '',
    zipCode: '',
    stripeSDK: '',
    planPrice: '',
    planType: '',
    loading: false,
    areElementsReady: false,
    transactionDetails: '',
    isCardElement: false,
    isExpiryElement: false,
    isCvvElement: false,
    cardBrand: 'unknown',
  };

  componentWillReceiveProps = (props) => {
    if (props.create_payment && props.create_payment.data) {
      this.setState(
        {
          loading: false,
        },
        () => redirectToCheckout(props.create_payment.data)
      );
    }
    if (props.create_payment && props.create_payment.success) {
      this.setState({
        transactionDetails: props.create_payment.data,
        loading: false,
        successStatus: true,
      });
    } else if (props.create_payment && !props.create_payment.success) {
      this.setState({
        transactionDetails: props.create_payment.error,
        loading: false,
        successStatus: false,
      });
    }
  };

  changeHandler = (event) => {
    if (event.target.className === 'firstName' || event.target.className === 'lastName') {
      if (isNaN(event.target.value) && event.target.value !== '') {
        this.setState({ [event.target.className]: event.target.value });
        document.getElementById(event.target.className).style.borderColor = '#ccc';
      } else if (!event.target.value) {
        this.setState({ [event.target.className]: '' });
        document.getElementById(event.target.className).style.borderColor = '#ea4335';
      }
    } else if (event.target.className === 'zipCode') {
      if (/^\d+$/.test(event.target.value) && event.target.value.length < 7) {
        this.setState({ [event.target.className]: event.target.value });
        document.getElementById(event.target.className).style.borderColor = '#ccc';
      } else if (!event.target.value) {
        this.setState({ [event.target.className]: '' });
        document.getElementById(event.target.className).style.borderColor = '#ea4335';
      }
    }
  };

  handleElementChange = (event) => {
    if (event.elementType === 'cardNumber') {
      if (event.empty || !event.complete) {
        this.setState({ isCardElement: false, cardBrand: event.brand });
        document.getElementById('isCardElement').style.borderColor = '#ea4335';
      } else {
        this.setState({ isCardElement: true, cardBrand: event.brand });
        document.getElementById('isCardElement').style.borderColor = '#ccc';
      }
    } else if (event.elementType === 'cardExpiry') {
      if (event.empty || !event.complete) {
        this.setState({ isExpiryElement: false });
        document.getElementById('isExpiryElement').style.borderColor = '#ea4335';
      } else {
        this.setState({ isExpiryElement: true });
        document.getElementById('isExpiryElement').style.borderColor = '#ccc';
      }
    } else {
      if (event.empty || !event.complete) {
        this.setState({ isCvvElement: false });
        document.getElementById('isCvvElement').style.borderColor = '#ea4335';
      } else {
        this.setState({ isCvvElement: true });
        document.getElementById('isCvvElement').style.borderColor = '#ccc';
      }
    }
  };

  componentsReady = (status) => {
    if (status === 'cardReady') {
      cardReady = true;
    } else if (status === 'expiryReady') {
      expiryReady = true;
    } else {
      cvvReady = true;
    }
    if (cardReady && expiryReady && cvvReady) {
      this.setState({ areElementsReady: true });
    }
  };

  orderDetailsTest = () => {
    return (
      <div className="col-sm-12 col-lg-5 col-md-5 col-12 ">
        <center>
          <span className="PaymentHeaders">Order Details</span>
        </center>
        <div className="orderCardInfo">
          <div className="row my-3">
            <div className="col-6 ">
              <h6>Plan Type: </h6>
            </div>
            <div className="col-6">
              <strong>{this.props.planDetails.name}</strong>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-6 ">
              <h6> {this.props.planDetails.recurring ? `Tests per month:` : 'Number of Tests:'} </h6>
            </div>
            <div className="col-6">
              <strong>{this.props.planDetails.credits ? this.props.planDetails.credits : 'Unlimited'}</strong>
            </div>
          </div>
          {this.props.plan && this.props.plan.selectedMonth && this.props.planDetails.recurring ? (
            <div className="row my-3">
              <div className="col-6 ">
                <h6>Expiry: </h6>
              </div>
              create-payment-session
              <div className="col-6">
                <strong>{this.props.plan.selectedMonth}</strong>
              </div>
            </div>
          ) : null}

          {this.props.plan && this.props.plan.totalDiscount ? (
            <div className="row my-3">
              <div className="col-6">
                <h6>Discount:</h6>
              </div>
              <div className="col-6">
                <strong>{this.props.plan.totalDiscount}%</strong>
              </div>
            </div>
          ) : null}

          <div className="row my-3">
            <div className="col-6">
              <h6>Total Amount:</h6>
            </div>
            <div className="col-6">
              <strong>{`SEK ${this.props.planDetails.price}`}</strong>
            </div>
          </div>
        </div>
      </div>
    );
  };

  cardDetailsForm = () => {
    return (
      <div>
        <center>
          <span className="PaymentHeaders">Payment Details</span>
        </center>
        <div className="cardInfo">
          <form onSubmit={this.submitHandler}>
            <div className="row">
              <div className="col-sm-12 col-lg-12 wrapperCustomerInfo">
                <div className="name row ">
                  <div className="col-sm-6 col-xs-6">
                    <label>First Name</label>
                    <input
                      type="text"
                      id="firstName"
                      className="firstName"
                      value={this.state.firstName}
                      placeholder="First Name"
                      onChange={this.changeHandler}
                    />
                  </div>
                  <div className="col-sm-6 col-xs-6">
                    <label>Last Name</label>
                    <input
                      type="text"
                      id="lastName"
                      className="lastName"
                      value={this.state.lastName}
                      placeholder="Last Name"
                      onChange={this.changeHandler}
                    />
                  </div>
                </div>
                <div id="creditCardInfo">
                  <label data-html-id="25"> Card Number </label>
                  <span className="brand">
                    {this.state.cardBrand !== 'unknown' ? (
                      <img src={imageConstants[this.state.cardBrand]} alt={this.state.cardBrand} />
                    ) : null}
                  </span>
                  <div id="isCardElement" className="cardElement">
                    <CardNumberElement
                      onReady={() => this.componentsReady('cardReady')}
                      onChange={this.handleElementChange}
                    />
                  </div>
                </div>
                <div className="name row ">
                  <div id="expiryDate" className="col-sm-4 col-xs-4">
                    <label>Expiry Date</label>
                    <div id="isExpiryElement" className="expiryElement">
                      <CardExpiryElement
                        onReady={() => this.componentsReady('expiryReady')}
                        onChange={this.handleElementChange}
                      />
                    </div>
                  </div>
                  <div id="cvc" className="col-sm-4 col-xs-4">
                    <label>CVC/CVV</label>
                    <div id="isCvvElement" className="cvvElement">
                      <CardCVCElement
                        onReady={() => this.componentsReady('cvvReady')}
                        onChange={this.handleElementChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-4">
                    <label data-html-id="29">Zip code</label>
                    <input
                      type="text"
                      placeholder="165857"
                      id="zipCode"
                      className="zipCode"
                      value={this.state.zipCode}
                      onChange={this.changeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button id="submitButton" type="submit" className="submitButton">
              PAY {this.state.planPrice / 100} SEK
            </button>
          </form>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="row cardPayment-container">
        {this.state.loading ? (
          <div>
            <SimpleSpinner />
            <Spinner title="Please wait while your payment is processing" />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    create_payment: state.data['create-payment'],
    getPaymentSession: state.data['create-payment-session'],
    plan: state.data.planDetails,
  };
};

export default injectStripe(connect(mapStateToProps, null)(CardPayment));
