export const imageConstants = {
  logo: require('../assets/Images/logo-white.svg'),
  avatar: require('../assets/Images/placeholder-avatar.png'),
  visa: require('../assets/Images/visa.png'),
  mastercard: require('../assets/Images/mastercard.png'),
  amex: require('../assets/Images/amex.png'),
  discover: require('../assets/Images/discover.png'),
  jcb: require('../assets/Images/jcb.png'),
  diners: require('../assets/Images/diners.png'),
};
