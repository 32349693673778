export const tabs = {
  recurring: 'recurring',
  purchase: 'purchase',
  purchaseATest: 'Purchase Tests',
  card: 'card',
  wallet: 'wallet',
  payPal: 'payPal',
};

export const RecurringPlans = [
  {
    id: '1',
    name: 'Standard',
    type: 'recurring',
    price: 1900,
    isRecommended: true,
    duration: 'per month',
    testProvided: '5',
  },
  {
    id: '2',
    name: 'Premium',
    type: 'recurring',
    price: 3500,
    isRecommended: false,
    duration: 'per month',
    testProvided: '15',
  },
  {
    id: '3',
    name: 'Enterprise',
    type: 'recurring',
    price: 7900,
    isRecommended: false,
    duration: 'per month or more',
    testProvided: 'Unlimited',
  },
];

export const customPlan = {
  price: 380,
};

export const currency = {
  sek: 'sek',
};

export const countryCode = {
  sweden: 'SE',
};
