import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as appConstants from '../utils';
import { setPlanDetails } from '../actions';

let tempMonth = {};
class Plan extends Component {
  state = {
    price: this.props.numberOfMonth
      ? this.props.numberOfMonth[0].price
      : this.props.purchaseData
      ? this.props.purchaseData.price
      : null,
    discountPrice: this.props.numberOfMonth
      ? this.props.numberOfMonth[0].price
      : this.props.purchaseData
      ? this.props.purchaseData.price
      : null,
    credits: 1,
    name: this.props.purchaseData ? this.props.purchaseData.name : null,
    selectedMonth: this.props.numberOfMonth ? this.props.numberOfMonth[0].months : 1,
    totalDiscount: null,
    _id: this.props.purchaseData._id,
    limitless: this.props.purchaseData.limitless ? true : false,
    type: this.props.purchaseData.name ? this.props.purchaseData.name : null,
    recurring: this.props.purchaseData.recurring ? this.props.purchaseData.recurring : false,
    description: this.props.purchaseData.description ? this.props.purchaseData.description : null,
    active: this.props.purchaseData.active ? this.props.purchaseData.active : false,
    archived: this.props.purchaseData.archived ? this.props.purchaseData.archived : false,
  };

  componentDidMount() {
    tempMonth[this.props.initialVariable] = 0;
    if (!this.props.planDetails) {
      this.props.setPlanDetails(this.state);
    } else {
      if (this.props.planDetails.isRecommended) {
        this.props.setPlanDetails({
          ...this.props.planDetails,
          selectedMonth: this.state.selectedMonth,
          price: this.state.price,
        });
      }
    }
  }

  handleNumberOfMonth = (event) => {
    if (event.target.className === 'increment') {
      if (tempMonth[this.props.initialVariable] < this.props.numberOfMonth.length - 1) {
        tempMonth[this.props.initialVariable] = tempMonth[this.props.initialVariable] + 1;
        this.setState({
          credits: this.props.planDetails.credits,
          price: this.props.numberOfMonth[tempMonth[this.props.initialVariable]].price,
          selectedMonth: this.props.numberOfMonth[tempMonth[this.props.initialVariable]].months,
          totalDiscount: this.props.numberOfMonth[tempMonth[this.props.initialVariable]].percent,
        });
        this.props.setPlanDetails({
          ...this.props.planDetails,
          credits: this.props.planDetails.credits,
          price: this.props.numberOfMonth[tempMonth[this.props.initialVariable]].price,
          selectedMonth: this.props.numberOfMonth[tempMonth[this.props.initialVariable]].months,
          totalDiscount: this.props.numberOfMonth[tempMonth[this.props.initialVariable]].percent,
        });
        this.props.selectedPlan(this.props.planDetails);
      }
    } else if (event.target.className === 'decrement') {
      if (tempMonth[this.props.initialVariable] > 0) {
        tempMonth[this.props.initialVariable] = tempMonth[this.props.initialVariable] - 1;
        this.setState({
          credits: this.props.planDetails.credits,
          price: this.props.numberOfMonth[tempMonth[this.props.initialVariable]].price,
          selectedMonth: this.props.numberOfMonth[tempMonth[this.props.initialVariable]].months,
          totalDiscount: this.props.numberOfMonth[tempMonth[this.props.initialVariable]].percent,
        });
        this.props.setPlanDetails({
          ...this.props.planDetails,
          credits: this.props.planDetails.credits,
          price: this.props.numberOfMonth[tempMonth[this.props.initialVariable]].price,
          selectedMonth: this.props.numberOfMonth[tempMonth[this.props.initialVariable]].months,
          totalDiscount: this.props.numberOfMonth[tempMonth[this.props.initialVariable]].percent,
        });
        this.props.selectedPlan(this.props.planDetails);
      }
    } else {
      this.props.selectedPlan(this.props.planDetails);
      this.props.setPlanDetails({
        ...this.props.planDetails,
        selectedMonth: this.state.selectedMonth,
        price: this.state.price,
      });
    }
  };

  handleNumberOfTests = (event) => {
    let price = this.state.price,
      discountPrice = this.state.discountPrice,
      credits = this.state.credits;
    if (event.target.className === 'increment') {
      price = this.state.price + this.props.purchaseData.price;
      credits = this.state.credits + 1;
    } else {
      if (this.state.credits > 1) {
        price = this.state.price - this.props.purchaseData.price;
        credits = this.state.credits - 1;
      }
    }
    if (credits >= 10 && credits <= 19) {
      discountPrice = price - (price / 100) * 15;
    } else if (credits >= 20) {
      discountPrice = price - (price / 100) * 25;
    } else {
      discountPrice = price;
    }
    this.setState({
      credits: credits,
      price: price,
      discountPrice: discountPrice,
    });
    this.props.setPlanDetails({
      ...this.props.purchaseData,
      type: this.props.purchaseData.name,
      credits: credits,
      price: price,
    });
  };

  renderRecurringPlans = () => {
    return (
      <div
        id={this.props.planDetails._id}
        onClick={(event) => this.handleNumberOfMonth(event)}
        className={this.props.wholeClass}
      >
        <div className={this.props.typeClass}>
          <p>{this.props.planDetails.name}</p>
        </div>
        <div className="plan">
          <div className="planHeaderRecurring">
            <div>
              {this.state.price}
              <font className="planCurrency">SEK</font>
            </div>
            <div>
              <h6 className="discountText">
                {' '}
                {this.state.totalDiscount ? `( save ${this.state.totalDiscount}% )` : null}
              </h6>
            </div>
            <div className="editablePlanRecurring">
              <div className="decrement" onClick={(event) => this.handleNumberOfMonth(event)}>
                -
              </div>
              <div className="numberOfTests">{this.state.selectedMonth} month</div>
              <div className="increment" onClick={(event) => this.handleNumberOfMonth(event)}>
                +
              </div>
            </div>
          </div>

          <div className="content">
            <ul>
              <li>
                {!this.props.planDetails.credits ? (
                  <strong>unlimited</strong>
                ) : (
                  <strong>{this.props.planDetails.credits}</strong>
                )}{' '}
                tests per month
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  renderPurchaseTestTab = () => {
    return (
      <div>
        <div className="mb-3">
          <span>
            Get <b>15%</b> off on 10 or more tests
          </span>
          <br />
          <span>
            Get <b>25%</b> off on 20 or more tests
          </span>
        </div>
        <div className="wholeDefault">
          <div className="typeDefault">
            <p>Select Tests</p>
          </div>
          <div className="plan">
            <div className={this.state.discountPrice != this.state.price ? 'planHeaderOnDiscount' : 'planHeader'}>
              {this.state.discountPrice != this.state.price ? (
                <div className="discountedPrice">
                  {this.state.price}
                  <font className="planCurrency">SEK</font>
                </div>
              ) : (
                ''
              )}
              {this.state.discountPrice}
              <font className="planCurrency">SEK</font>
              <div className="editablePlan">
                <div className="decrement" onClick={(event) => this.handleNumberOfTests(event)}>
                  -
                </div>
                <div className="numberOfTests">{this.state.credits}</div>
                <div className="increment" onClick={(event) => this.handleNumberOfTests(event)}>
                  +
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.props.activeTabName !== appConstants.tabs.purchase
      ? this.renderRecurringPlans()
      : this.renderPurchaseTestTab();
  }
}

export default connect(null, { setPlanDetails })(Plan);
