import React, { Component } from 'react';
import { imageConstants, getConfig } from '../utils';
import WebsocketInstance from '../utils/websocket';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class Header extends Component {
  state = {
    userDetails: null,
    showTest: null,
    imageError: null,
  };

  componentDidMount() {
    let interval = setInterval(() => {
      if (WebsocketInstance.isWebsocketConnected()) {
        clearInterval(interval);
        WebsocketInstance.sendMessage({ _handler: 'get-payment-accountInfo', isReact: true });
      }
    }, 200);
  }

  componentWillReceiveProps(props) {
    if (props.get_payment_accountInfo && props.get_payment_accountInfo.success) {
      this.setState({
        userDetails: props.get_payment_accountInfo.data,
      });
    }

    if (props.load_plans && props.load_plans.success) {
      this.setState({
        showTest: props.load_plans.testsOnly,
      });
    }
  }

  imageHandler = () => {
    this.setState({
      imageError: require('../images/placeholder-avatar.png'),
    });
  };

  renderUserDetails() {
    return (
      <div
        className=" header-userDetails-container row mr-2"
        onClick={() => (window.location = `${getConfig().REDIRECTION_ROOT_URL}/dashboard`)}
      >
        <div className="userDetailsContainer">
          <h4 className="userName">{this.state.userDetails.name}</h4>
          <h6 className="companyName">{this.state.userDetails.company[0].name}</h6>
        </div>
        <img
          src={this.state.imageError ? this.state.imageError : this.state.userDetails.avatar}
          onError={() => this.imageHandler()}
          alt="profile pic"
          className="profileImage"
        />
      </div>
    );
  }

  renderLinks() {
    if (this.state.showTest) {
      return (
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <li className="mr-3">
              <a className="nav-link header-links" href={getConfig().REDIRECTION_TEST_URL}>
                Tests
              </a>
            </li>
            <li className="mr-3">
              <a className="nav-link header-links" href={getConfig().REDIRECTION_SEND_TEST_URL}>
                Send Test
              </a>
            </li>
          </ul>
          <div className="form-inline my-2 my-lg-0">{this.state.userDetails ? this.renderUserDetails() : null}</div>
        </div>
      );
    } else if (this.state.showTest === false) {
      return (
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <li className="mr-3">
              <a className="nav-link header-links" href={getConfig().REDIRECTION_MANAGE_JOBS_URL}>
                Jobs<span className="sr-only">(current)</span>
              </a>
            </li>
            <li className="mr-3">
              <a className="nav-link header-links" href={getConfig().REDIRECTION_TEST_URL}>
                Tests
              </a>
            </li>
            <li className="mr-3">
              <a className="nav-link header-links" href={getConfig().REDIRECTION_CANDIDATES_URL}>
                Candidates
              </a>
            </li>
            <li className="mr-3">
              <a className="nav-link header-links" href={getConfig().REDIRECTION_MESSAGES_URL}>
                Messages
              </a>
            </li>
          </ul>
          <div className="form-inline my-2 my-lg-0">{this.state.userDetails ? this.renderUserDetails() : null}</div>
        </div>
      );
    }
  }

  render = () => {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark"
        onClick={() => (window.location = `${getConfig().REDIRECTION_ROOT_URL}/dashboard`)}
      >
        <Link to="/plans">
          <img src={imageConstants.logo} alt="Logo" className="pull-xs-left main-logo aos-init aos-animate fsLogo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {this.renderLinks()}
      </nav>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    get_payment_accountInfo: state.data['get-payment-accountInfo'],
    load_plans: state.data['load-plans'],
  };
};

export default connect(mapStateToProps, null)(Header);
