import React from 'react';
import Websocket from 'react-websocket';
import { setAPIResponse } from '../actions';
import { connect } from 'react-redux';
import { errorHandler } from './errorHandler';
import { getConfig } from '../utils';
import { getCookie } from './cookieParser';

let self;

class WebsocketInstance extends React.Component {
  state = {
    session: getCookie('swsSession') ? getCookie('swsSession') : null,
    checkSession: false,
    isConnected: false,
  };

  componentWillReceiveProps(props) {
    console.log('getCookie(swsSession): ', getCookie('swsSession'));
    if (!getCookie('swsSession')) {
      if (props.sessionData) {
        this.setState({
          session: props.sessionData,
        });
      } else {
        console.log('REDIRECTED from websocket.ks');
        window.location.href = getConfig().REDIRECTION_URL;
      }
    } else {
      this.setState({
        session: getCookie('swsSession'),
      });
    }
  }

  static sendMessage = (data) => {
    let interval = setInterval(() => {
      if (self.state.isConnected) {
        clearInterval(interval);
        self.refWebSocket.sendMessage(JSON.stringify({ ...data, _session: self.state.session }));
      }
    }, 200);
  };

  static isWebsocketConnected = () => {
    return self.state.isConnected;
  };

  handleData = (data) => {
    let details = JSON.parse(data);
    errorHandler(details);
    this.props.setAPIResponse(details);
  };

  handleClose = (err) => {
    console.log('Err', err);
  };

  handleOpen = (message) => {
    // TODO: Need to crate a function which will handle object for sending data
    self.setState({
      isConnected: true,
    });
    setInterval(() => {
      self.refWebSocket.sendMessage(JSON.stringify({ heartbeat: 'react' }));
    }, 5000);
  };

  render = () => {
    self = this;
    return (
      <Websocket
        url={`${getConfig().WEBSOCKET_ROOT_URL}/live`}
        onMessage={this.handleData.bind(this)}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        debug={true}
        ref={(Websocket) => {
          this.refWebSocket = Websocket;
        }}
      />
    );
  };
}

const mapStateToProps = (state) => {
  return {
    planDetails: state.data.planDetails,
    defaultPlanKey: state.data.defaultPlanKey,
    sessionData: state.data.sessionData,
  };
};

export default connect(mapStateToProps, { setAPIResponse })(WebsocketInstance);
